/**
 * @file mutations.js is a file for all Invoice mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} CREATE_INVOICE
 * 
 * @summary
 * - This mutation will create a new invoice.
 * - This mutation will return an invoice object.
 * - This mutation will return null if the invoice could not be created.
 * 
 * @param {Object} input - The input object containing the invoice data.
 * @param {String} input.customer - The ID of the customer for the invoice.
 * @param {String} input.service_date - The date of the service for the invoice.
 * @param {String} input.service_description - The description of the service for the invoice.
 * @param {Number} input.total_amount - The total amount for the invoice.
 * @param {Array} input.items - The items for the invoice.
 * @param {Number} input.discount - The discount for the invoice.
 */
export const CREATE_INVOICE = gql`
	mutation createInvoice($input: CreateInvoiceInput!) {
		createInvoice(input: $input) {
			_id
			customer {
				_id
				name
				email
				phone
				address {
					line1
					line2
					city
					state
					zip
					country
				}
				createdAt
				updatedAt
			}
			service_date
			service_description
			total_amount
			tax_fee
			transaction_fee
			items {
				_id
				item
				quantity
				rate
				amount
			}
			discount
			status
			stripeInvoiceId
			refund {
				receiptUrl
				refundedOn
			}
			createdAt
			updatedAt
		}
	}
`;

/**
 * @const {Object} SEND_INVOICE
 * 
 * @summary
 * - This mutation will send an existing invoice.
 * 
 * @param {String} id - The ID of the invoice to send.
 */
export const SEND_INVOICE = gql`
	mutation sendInvoice($id: String!) {
		sendInvoice(id: $id)
	}
`;


/**
 * @const {Object} CANCEL_INVOICE
 * 
 * @summary
 * - This mutation will cancel an existing invoice.
 * - This mutation will return a Boolean.
 * 
 * @param {String} id - The ID of the invoice to cancel. 
 */
export const CANCEL_INVOICE = gql`
	mutation cancelInvoice($id: String) {
		cancelInvoice(id: $id)
	}
`;

/**
 * @const {Object} REFUND_INVOICE
 * 
 * @summary
 * - This mutation will refund an existing invoice.
 * 
 * @param {String} id - The ID of the invoice to refund.
 * 
 */
export const REFUND_INVOICE = gql`
	mutation refundInvoice($id: String) {
		refundInvoice(id: $id)
	}
`;

/**
 * @const {Object} CHECK_INVOICE_STATUS
 * 
 * @summary
 * - This mutation will check the status of an existing invoice.
 * - This mutation will return a String
 * 
 * @param {String} id - The ID of the invoice to check the status of.
 */
export const CHECK_INVOICE_STATUS = gql`
	mutation checkInvoiceStatus($id: String) {
		checkInvoiceStatus(id: $id)
	}
`;

/**
 * @const {Object} DELETE_INVOICE
 * 
 * @summary
 * - This mutation will delete an existing invoice.
 * - This mutation will return a Boolean.
 * 
 * @param {String} id - The ID of the invoice to delete.
 */
export const DELETE_INVOICE = gql`
	mutation deleteInvoice($id: String) {
		deleteInvoice(id: $id)
	}
`;